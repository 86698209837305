import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

// COMPONENTS
import Navbar from "../components/Navbar";
import Footer from '../components/Footer'

// IMPORT BUTTON
import MentionsButton from '../components/button/MentionsButton'

const Projects = ({ projects }) => {

    const navigate = useNavigate();
    const [category, setCategory] = useState('ALL')
    const location = useLocation();

    const filteredProjects = useMemo(() => {
        const uniqueProjects = [];
        projects.forEach((item) => {
            // Projeyi daha önce eklememişsek ve kategorisi seçili kategoriye eşleşiyorsa, projeyi ekle
            if (!uniqueProjects.some((uniqueItem) => uniqueItem.id === item.id) && (category === 'ALL' || item.type === category)) {
                uniqueProjects.push(item);
            }
        });
        return uniqueProjects;
    }, [category, projects]);

    // SCROLL UP
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    useEffect(() => {
        // URL'den kategori bölümünü alın
        const categoryFromURL = location.hash.substring(1).toUpperCase(); // '#RESIDENTIAL' -> 'RESIDENTIAL'
        // Geçerli bir kategori ise, kategori filtresini ayarlayın
        if (['ALL', 'COMMERCIAL', 'RESIDENTIAL'].includes(categoryFromURL)) {
            setCategory(categoryFromURL);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            // Geçerli bir kategori değilse, varsayılanı kullanın veya isteğe bağlı olarak bir hata işleyebilirsiniz.
            setCategory('ALL');
        }
    }, [location]);

    return (
        <div>
            <Navbar section={20} projects={projects}/>
            <MentionsButton />
            <div className='h-[60px] w-full bg-transparent'></div>
            <div className='bg-black w-full'>
                <div className="w-full h-full flex flex-col relative max-w-screen-2xl m-auto py-20">
                    {/* CATEGORY */}
                    <div className="flex flex-row custom-font py-4 md:pt-0 px-5 z-10 w-full justify-start items-center md:py-2 md:mt-10">
                        <div className="flex flex-row gap-5 md:gap-10">
                            <div className={`${category === 'ALL' ? 'opacity-100' : 'opacity-40'} flex flex-col items-start`}>
                                <div className="cursor-pointer text-sm md:text-base text-white" onClick={() => navigate('/projects/#ALL')}>All</div>
                            </div>
                            <div className={`${category === 'COMMERCIAL' ? 'opacity-100' : 'opacity-40'} flex flex-col items-start `}>
                                <div className="cursor-pointer text-sm md:text-base text-white" onClick={() => navigate('/projects/#COMMERCIAL')}>Commercial</div>
                            </div>
                            <div className={`${category === 'RESIDENTIAL' ? 'opacity-100' : 'opacity-40'} flex flex-col items-start`}>
                                <div className="cursor-pointer text-sm md:text-base text-white" onClick={() => navigate('/projects/#RESIDENTIAL')}>Residential</div>
                            </div>
                        </div>
                    </div>
                    {/* GALLERY */}
                    <div className={`w-full md:pt-0 px-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 md:gap-1`} >
                        {filteredProjects.reverse().map((item) => (
                            <div key={item.id} className="duration-500 relative project-item">
                                <a href={`/projects/${item.name.split(' ').join('-')}`}>
                                    <div className="relative cursor-pointer">
                                        <img className="object-cover h-56 md:h-56 w-full" src={item.cover} alt={item.name} loading="lazy" />
                                        <div className="absolute top-0 left-0 w-full h-full  md:opacity-0 transition-opacity duration-500 md:hover:opacity-100 bg-black/20 flex justify-start items-end">
                                            <div className="text-white text-xl px-4 py-2 rounded-md md:ml-2 md:mb-2 cursor-pointer custom-font">
                                                {item.name}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer section={true} />
        </div>

    )
}

export default Projects