//IMPORT IMAGE COVER AND IMAGES FROM FOLDER
// const projectPhotos = (projectName, number) => {
//     const photos = [];
//     for (let i = 0; i <= number; i++) {
//         photos.push(
//             require(`../media/photos/${projectName}/0${i}.webp`)
//         );
//     }
//     return photos;
// };

//IMPORT PLANS FROM FOLDER
// const projectPlans = (projectName, number) => {
//     const photos = [];
//     for (let i = 0; i <= number; i++) {
//         photos.push(
//             require(`../media/photos/${projectName}/Plan${i}.jpg`)
//         );
//     }
//     return photos;
// };

//IMPORT THUMBNAILS
const projectThumbnails = (projectName) => {
    const photo = `https://storage.googleapis.com/manoto-project/photos/${projectName}/Thumbnail.webp`
    return photo
}

// IMPORT PLANS FROM CLOUD
const projectPlans = (projectName, number) => {
    const photos = [];
    for (let i = 0; i <= number; i++) {
        photos.push(`https://storage.googleapis.com/manoto-project/photos/${projectName}/Plan${i}.jpg`);
    }
    return photos;
};

// IMPORT IMAGE COVER AND IMAGE FROM CLOUD
const projectPhotos = (projectName, number) => {
    const photos = [];
    for (let i = 0; i <= number; i++) {
        photos.push(`https://storage.googleapis.com/manoto-project/photos/${projectName}/0${i}.webp`);
    }
    return photos;
};

export const projects = [
    {
        id: 1,
        name: 'Yıldırım Headquarter',
        type: 'COMMERCIAL',
        cover: projectPhotos('01', 0)[0],
        plan: projectPlans('01', 2),
        image: projectPhotos('01', 20),
        thumbnail: projectThumbnails('01'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "The exterior of Yıldırım Tower building makes a difference with its original design and adds prestige to the building. We worked on the headquarter section on the top three floors which contains administive floor, manager and ceo offices floor and the terrace floor for lounge and private areas. Offices where material usage is abundant but effective and designed fixed furnitures produced with high care aimed to impress. A unique design possibility with the opportunities provided by the exterior of this building became a eye-catching detail viewed from every aspect. ",
    },
    {
        id: 11,
        name: 'Villa E',
        type: 'RESIDENTIAL',
        cover: projectPhotos('11', 0)[0],
        plan: projectPlans('11', 2),
        image: projectPhotos('11', 17),
        thumbnail: projectThumbnails('11'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "This site consisting of 4 villas was built in the Turgutreis, Bodrum which is the Turkey's one of the most prefereed entertainment district to spend the summer. The purpose of each villa is to be rented on a weekly or montly basis. It has 8 bedrooms with private bathrooms and dressing area within. One of the bedrooms is for conciergerie to serve the guests when its needed. The basement floor is also planned to be rented as 2+1 (2 bedrooms, 1 living room, 1 kitchen) for small groups. The basic principle was creating an environment where guests can stay without any deficiencies and also to make sure they leave satisfied. ",
    },
    {
        id: 12,
        name: 'Demirler Showroom',
        type: 'COMMERCIAL',
        cover: projectPhotos('12', 0)[0],
        plan: projectPlans('12', 0),
        image: projectPhotos('12', 6),
        thumbnail: projectThumbnails('12'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Demirler Showroom 1 project is for a company that sells wooden products in retail mostly doors and parquet. A showroom with an industrial design aesthetic is a captivating blend of form and function. Upon entering, you are immediately immersed in a space that celebrates the raw, utilitarian beauty of industrial elements. Every piece exudes a sense of rugged elegance, transforming the showroom into an experiential gallery where visitors can appreciate the marriage of industrial aesthetics with modern design sensibilities. ",
    },
    {
        id: 13,
        name: 'Midas Hotel',
        type: 'COMMERCIAL',
        cover: projectPhotos('13', 0)[0],
        plan: projectPlans('13', 3),
        image: projectPhotos('13', 12),
        thumbnail: projectThumbnails('13'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Midas Hotel located in the center of Ankara on Tunus Street stays in the middle of the practical streets such as Tunalı Hilmi and Kızılay. The interior design of this city hotel, featuring spacious suites and an enchanting rooftop bar, is a testament to modern luxury and urban sophistication. The rooftop bar becomes a vibrant hub for socializing, offering craft cocktails and delectable cuisine against the backdrop of city lights. Floor-to-ceiling windows offer panoramic views of the bustling city below, creating an atmosphere of urban serenity. This city hotel's interior design effortlessly combines modern luxury with an urban edge, providing an unforgettable experience for travelers looking to immerse themselves in the vibrant pulse of the metropolis.",
    },
    {
        id: 14,
        name: 'Confidential VIP Interior',
        type: 'COMMERCIAL',
        cover: projectPhotos('14', 0)[0],
        plan: projectPlans('14', 1),
        image: projectPhotos('14', 12),
        thumbnail: projectThumbnails('14'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Confidential VIP Bank Interior designed exclusively for discerning executives embodies a pinnacle of luxury and sophistication, where the seamless integration of wood and marble materials, complemented by modern lighting, creates an opulent sanctuary of financial service. The lighting systems, accentuates the architectural details, casting an artful glow across the room. To accentuate this floors' grandeur, combination of marble and metal wall paneling in walls is strategically emploted, adding a contemporary edge and a touch of glamour. The juxtaposition of these premium materials creates an environment that seamlessly marries the classic with the modern.The interior transcends mere functionality, becoming a symbol of power and prestige for executives seeking a banking experience that matches their elevated status.",
    },
    {
        id: 15,
        name: 'Confidential Bank Interior',
        type: 'COMMERCIAL',
        cover: projectPhotos('15', 0)[0],
        plan: projectPlans('15', 2),
        image: projectPhotos('15', 22),
        thumbnail: projectThumbnails('15'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Confidential Bank Interior design is a reflection of the evolving bankind landscape, where the traditional brick-and-mortar instutitions adapt to meet the changing needs of customers in the digital age. Our design language emphasizes a harmonious blend of aesthetics, functionality, and technology. The aim is to create a welcoming atmosphere that goes beyond transactional interactions, providing spaces for consultation, collabration and financial planning. Wooden panelling adorns walls, providing warmth and texture, while sleek wooden desks and cabinets offer a touch of opulence. The interplay of natural light, augmented by these modern lighting solutions, creates a dynamic and welcoming environment that is both conducive to productivity and visually striking. ",
    },
    {
        id: 16,
        name: 'ASM Tower 41',
        type: 'COMMERCIAL',
        cover: projectPhotos('16', 0)[0],
        plan: projectPlans('16', 2),
        image: projectPhotos('16', 7),
        thumbnail: projectThumbnails('16'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "ASM Tower 41 is a building estate located in Ankara offering lots of social space for its residents. We designed entrance and floor halls, lounge areas that contains a meeting room, a bar and playroom area which can be also used for birthdays or special days. The social facility is the highlight of this building estate which we aim to design it to meet all the needs of the members. Concrete shades combined with natural dark wood veneers and a sport complex spread over wide, breathable areas.",
    },
    {
        id: 17,
        name: 'Elysium Garden Hotel',
        type: 'COMMERCIAL',
        cover: projectPhotos('17', 0)[0],
        plan: projectPlans('17', 2),
        image: projectPhotos('17', 10),
        thumbnail: projectThumbnails('17'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "The interior design of the Bohemian-inspired hotel is a captivating journey into a world of artistic eclecticism and free-spirited charm. With its rich tapestry of colors, patterns, and textures, the hotel exudes a sense of bohemian vibrancy and individuality. Each room, adorned with vintage tells a story of artistic expression and cultural fusion. The lobby and restaurant area is a welcoming oasis of plush cushions and low-slung sofas, creating an ambiance of relaxed intimacy. The overall effect is an interior design that encourages guests to embrace the unconventional, celebrate diversity, and revel in the beauty of the bohemian spirit, making it an ideal retreat for free-thinking souls seeking a uniquely enchanting stay.",
    },
    {
        id: 18,
        name: 'Villa D',
        type: 'RESIDENTIAL',
        cover: projectPhotos('18', 0)[0],
        plan: projectPlans('18', 1),
        image: projectPhotos('18', 10),
        thumbnail: projectThumbnails('18'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Located in Bodrum, Villa Mesa was designed by focusing on the comfort of the family. Creating a contemporary modern atmosphere here was the most significant step while projecting our vision. The furniture was designed minimally and with full function, allowing it to breathe without losing space. Light-toned natural materials chosen to preserve the summer breeze. ",
    },
    {
        id: 19,
        name: 'Confidential Business Interior',
        type: 'COMMERCIAL',
        cover: projectPhotos('19', 0)[0],
        plan: projectPlans('19', 1),
        image: projectPhotos('19', 8),
        thumbnail: projectThumbnails('19'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "In this project, which draws attention with its magnificent ceiling heights, we worked on two separate entrance halls, VIP and general. To create a premium perception, we paid attention to the use of genuine and natural materials, especially in furniture design. We also created a design that provides all acoustic sound controls to handle with this high ceiling provided by the architectural structure. ",
    },
    {
        id: 20,
        name: 'Villa M',
        type: 'RESIDENTIAL',
        cover: projectPhotos('20', 0)[0],
        plan: projectPlans('20', 1),
        image: projectPhotos('20', 20),
        thumbnail: projectThumbnails('20'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "In this project, which draws attention with its magnificent ceiling heights, we worked on two separate entrance halls, VIP and general. To create a premium perception, we paid attention to the use of genuine and natural materials, especially in furniture design. We also created a design that provides all acoustic sound controls to handle with this high ceiling provided by the architectural structure. ",
    },
    {
        id: 21,
        name: 'House Y',
        type: 'RESIDENTIAL',
        cover: projectPhotos('21', 0)[0],
        plan: projectPlans('21', 1),
        image: projectPhotos('21', 22),
        thumbnail: projectThumbnails('21'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Every inch of this house was designed tastefully with stylish details. Imposing fieldstone and natural wood veneers in different shade with adding metal wrought iron details grace this exclusive dublex flat. The living room gives us the opportunity to expand our design language with its high ceiling. The bedrooms also designed to improve each family member's quality of life while they exploring a journey in this house.",
    },
    {
        id: 22,
        name: 'Dental Clinic Demir',
        type: 'COMMERCIAL',
        cover: projectPhotos('22', 0)[0],
        plan: projectPlans('22', 0),
        image: projectPhotos('22', 7),
        thumbnail: projectThumbnails('22'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Dental Clinic Demir is located in Ankara. The first and main basis when designing the clinic is to ensure that it has a clean and hygienic environment. The materials were carefully selected to be dirt-resistant and bacteria-proof. With natural oak veener and brass metal finishes combined with comfortable furnitures for both dentists and patients, we achieved to create a space where we can entrust ourselves safely. ",
    },
    {
        id: 23,
        name: 'Electric Boat I',
        type: 'COMMERCIAL',
        cover: projectPhotos('23', 0)[0],
        plan: projectPlans('23', 1),
        image: projectPhotos('23', 4),
        thumbnail: projectThumbnails('23'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Electric boat interior design embodies a harmonious fusion of sustainable innovation and luxurious comfort. With an emphasis on eco-conscious materials and energy-efficient technologies, these interiors redefine the boating experience. Every element of the interior is thoughtfully curated, from ergonomic seating and clever storage solutions to state-of-the-art entertainment systems. Electric boats exemplify a new era in boating, where the allure of the water meets the principles of sustainability, resulting in interiors that epitomize both luxury and ecological responsibility.",
    },
    {
        id: 24,
        name: 'Electric Boat II',
        type: 'COMMERCIAL',
        cover: projectPhotos('24', 0)[0],
        plan: projectPlans('24', 1),
        image: projectPhotos('24', 6),
        thumbnail: projectThumbnails('24'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Electric boat interior design embodies a harmonious fusion of sustainable innovation and luxurious comfort. With an emphasis on eco-conscious materials and energy-efficient technologies, these interiors redefine the boating experience. Every element of the interior is thoughtfully curated, from ergonomic seating and clever storage solutions to state-of-the-art entertainment systems. Electric boats exemplify a new era in boating, where the allure of the water meets the principles of sustainability, resulting in interiors that epitomize both luxury and ecological responsibility.",
    },
    {
        id: 25,
        name: '7+1 Flat',
        type: 'RESIDENTIAL',
        cover: projectPhotos('25', 0)[0],
        plan: projectPlans('25', 0),
        image: projectPhotos('25', 10),
        thumbnail: projectThumbnails('25'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "This 7+1 flat which has very large living spaces, also includes a terrace as large as itself. The divination of rooms is planned as 5 rooms of the househould, 1 room for the conciergerie and 1 room for the study/home office. In order to avoid tiring the eyes in this large structure, we used fewer natural materials repetitively in harmony. The house has become more than just a home with the many functions we have added itself. ",
    },
    {
        id: 26,
        name: 'Ear Clinic Moment Beştepe',
        type: 'COMMERCIAL',
        cover: projectPhotos('26', 0)[0],
        plan: projectPlans('26', 0),
        image: projectPhotos('26', 4),
        thumbnail: projectThumbnails('26'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Ear Clinic Moment Beştepe which is a small clinic in structure is located in Ankara. The 'ear' itself allows us to take the ear shape as a form and reflect it on the design. The ceiling design and lighting elements at the entrance make the patients embrace the concepts as soon as they enter. Surrounding the offices with ribbed glass without blocking any light and using brass metal details, a stylish and clean-cut clinic emerges. ",
    },
    {
        id: 27,
        name: 'Confidential Business Building',
        type: 'COMMERCIAL',
        cover: projectPhotos('27', 0)[0],
        plan: projectPlans('27', 7),
        image: projectPhotos('27', 14),
        thumbnail: projectThumbnails('27'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "This confidential business building is the technology center in the fields of design, development, production, completion, renewal and after-sales services of air platforms in Turkey. In order to operate the head office, the design language must be a harmonious fusion of innovation and functionality. Tech-inspired shades like metallic silver and deep navy used occassionaly. Strategically placed seperators with vertical garden function flood the space with natural light, creating an inviting and energizing atmosphere. Overall, a forward-thinking, futuristic ambiance that inspires creativity and productivity. ",
    },
    {
        id: 28,
        name: 'Demirler Showroom II',
        type: 'COMMERCIAL',
        cover: projectPhotos('28', 0)[0],
        plan: projectPlans('28', 2),
        image: projectPhotos('28', 13),
        thumbnail: projectThumbnails('28'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Demirler Showroom 2 project is for a company that sells wooden products in retail mostly doors and parquet. Since this is the second showroom with the same company, we took the opportunity to change our design language in this particular one. The showroom's meticulous arrangement allows you to explore various wood species, finishes, and patterns, helping you envision how they could transform your living spaces. This showroom not only provides a tactile and visual experience but also expert guidance, ensuring that you make informed choices for your home or office, creating spaces that are both aesthetically pleasing and functionally practical.",
    },
    {
        id: 29,
        name: 'Fema Headquarter',
        type: 'COMMERCIAL',
        cover: projectPhotos('29', 0)[0],
        plan: projectPlans('29', 0),
        image: projectPhotos('29', 13),
        thumbnail: projectThumbnails('29'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Fema Construction is one of the well-know construction companies of Ankara, with its city viewed office in the Maidan. The interior design of this construction firm's building is a testament to the innovative and distinctive roundy structure that sets it apart from conventional architectural norms. The building's circular design not only creates a striking visual impression but also serves as a symbol of fluidity, unity, and creative thinking within the construction industry.Large, panoramic windows allow natural light to flood in, providing an inspiring backdrop to collaborative workspaces and meeting areas. Designed with meticulous attention to detail, the executive suite offers an elegant and private space for high-level meetings, strategic planning and decision-making. ",
    },
    {
        id: 30,
        name: 'Alimar Showroom',
        type: 'COMMERCIAL',
        cover: projectPhotos('30', 0)[0],
        plan: projectPlans('30', 3),
        image: projectPhotos('30', 8),
        thumbnail: projectThumbnails('30'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Alimar Jenerator Company which exports to many countries abroad and has a sales and technical service network in all regions within the country has its headquarters located in Ankara. We have the design of the head office as well as the showroom for this company. Since we designed the office first, we elaborated to ensure that the showroom integrated with the overall design. Taking advantage of the open ceiling design and steel columns we created an industrial atmosphere.",
    },
    {
        id: 31,
        name: 'India Embassy',
        type: 'COMMERCIAL',
        cover: projectPhotos('31', 0)[0],
        plan: projectPlans('31', 4),
        image: projectPhotos('31', 8),
        thumbnail: projectThumbnails('31'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "India Embassy Project was a collabration with Gökhan Aksoy Architecture firm. The interior design of the Indian Embassy Ambassador's residence is a harmonious blend of cultural heritage, refined elegance, and diplomatic charm. This space serves as a representation of India's rich and diverse traditions, with a color palette drawn from the vibrant hues of Indian textiles and art.In public areas, one might find exquisite artworks and sculptures that celebrate India's artistic legacy. Spaces for diplomatic meetings and events are designed with a sense of grandeur, emphasizing hospitality and the deep-rooted traditions of Indian diplomacy..",
    },
    {
        id: 32,
        name: 'Bangladesh Embassy',
        type: 'COMMERCIAL',
        cover: projectPhotos('32', 0)[0],
        plan: projectPlans('32', 1),
        image: projectPhotos('32', 8),
        thumbnail: projectThumbnails('32'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "The interior design language of Bangladesh Embassy reflects a unique fusion of tradition and modernity, crating a captivating aesthetic that embodies the nation's rich cultural heritage while embracing contemporary design trends. Traditional motifs, inspired by local crafts and folklore, are frequently incorporated into the decor, adding a sense of nostalgia and authenticity to the spaces. ",
    },
    {
        id: 33,
        name: 'The Business Building',
        type: 'COMMERCIAL',
        cover: projectPhotos('33', 0)[0],
        plan: projectPlans('33', 1),
        image: projectPhotos('33', 9),
        thumbnail: projectThumbnails('33'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "The interior design language of Bangladesh Embassy reflects a unique fusion of tradition and modernity, crating a captivating aesthetic that embodies the nation's rich cultural heritage while embracing contemporary design trends. Traditional motifs, inspired by local crafts and folklore, are frequently incorporated into the decor, adding a sense of nostalgia and authenticity to the spaces. ",
    },
    {
        id: 34,
        name: 'YDA Sample Flat',
        type: 'RESIDENTIAL',
        cover: projectPhotos('34', 0)[0],
        plan: projectPlans('34', 0),
        image: projectPhotos('34', 8),
        thumbnail: projectThumbnails('34'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "YDA Sample Flat which we worked on is 4+1 flat. We also designed the 1+1, 2+1 and 3+1 flats for the same building site. In each-sized flat, the aim was to design by loading a function to the space with our aesthetic. The small living room defined by aluminum joinery allows light field atmosphere at first impression. The harmony of the natural wood veeners and modern language of structure makes this flat stand out.",
    },
    {
        id: 35,
        name: 'YDA Sales Office',
        type: 'COMMERCIAL',
        cover: projectPhotos('35', 0)[0],
        plan: projectPlans('35', 0),
        image: projectPhotos('35', 13),
        thumbnail: projectThumbnails('35'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "YDA Sales Office Project came to us as a combination of all sample flats on the same building estate. In order to ensure that it goes in integrity while designing, we tried to create an ongoing atmosphere. Using natural materials and receiving light correctly we achieved that. Reception and waiting areas with their dynamic and nature-inspiring structure manage to attract customers. ",
    },
    {
        id: 36,
        name: 'Medova Hospital',
        type: 'COMMERCIAL',
        cover: projectPhotos('36', 0)[0],
        plan: projectPlans('36', 2),
        image: projectPhotos('36', 10),
        thumbnail: projectThumbnails('36'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Medova Hospital is constructed in Konya. Hygiene standards are prioritized for material section. In addition to this, design and color selection are made in accordance with physical and psychological comfort and meet the needs most appropriately. ",
    },
    {
        id: 37,
        name: 'Villa Ö',
        type: 'RESIDENTIAL',
        cover: projectPhotos('37', 0)[0],
        plan: projectPlans('37', 0),
        image: projectPhotos('37', 14),
        thumbnail: projectThumbnails('37'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Nestled amidst lush greenery, the villa exudes an exquisite blend of opulence and natural elegance through its use of materials. Upon entering, a foyer welcomes you with its sleek white marble flooring, which seamlessly transitions into warm wooden accents, setting the tone for the entire residence. The kitchen is a culinary marvel, feautiring marble countertops and island with the wooden cabinets, a perfect blend of functionality and style. ",
    },
    {
        id: 38,
        name: 'Dalsan HQ',
        type: 'COMMERCIAL',
        cover: projectPhotos('38', 0)[0],
        plan: projectPlans('38', 3),
        image: projectPhotos('38', 13),
        thumbnail: projectThumbnails('38'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Main building of Dalsan; one of the leading plasterboard manufacturers of Turkey. At the building, where we aim to show ‘less means coherence’, there are many high tech manufacturing tools alongside with research and development center. It is possible to feel a minimalistic design through the of earthy tones. Juxtaposition of concrete surface with marble and natural walnut’s coherence is highly emphasized.",
    },
    {
        id: 39,
        name: 'C Construction',
        type: 'COMMERCIAL',
        cover: projectPhotos('39', 0)[0],
        plan: projectPlans('39', 0),
        image: projectPhotos('39', 14),
        thumbnail: projectThumbnails('39'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "A modern construction office, characterized by ambient glow of blue LED lights, embodies a cutting-edge approach to workspace design. The mirrored columns not only lend an air of sophistication but also serve a functional purpose, amplifying the sense of spaciousness and allowing natural light to dance throughout the room. It is a testament to how aesthetics and functionality can seamlessly converge, enhancing the overall quality of work and exemplifying the modern evolution of construction offices.",
    },
    {
        id: 40,
        name: 'YDA Mae Office',
        type: 'COMMERCIAL',
        cover: projectPhotos('40', 0)[0],
        plan: projectPlans('40', 1),
        image: projectPhotos('40', 6),
        thumbnail: projectThumbnails('40'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "YDA Mae Office is another YDA sales office for a bulding estate in Yenimahalle, Ankara. In the fluidity that the light bright color used on marble floor adds to the space with the contribution of other tones found in nature, the whole internal structure creates a comfortable and pleasant setting for the customers. ",
    },
    {
        id: 2,
        name: 'Tekmer Technology Center',
        type: 'COMMERCIAL',
        cover: projectPhotos('02', 0)[0],
        plan: projectPlans('02', 0),
        image: projectPhotos('02', 12),
        thumbnail: projectThumbnails('02'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Tekmer is Turkey's first private enterprise Technology Development Center, located in Ankara.The entrepreneurs and investors meet under the same roof where the most modern and comfortable working conditions are offered by our fluid design. The circulation area is designed to be navigated by scooter or bicycle, thus trying to create a more accessible environment. Heavy use of blue led light and open ceiling design with barisol transition area defines an integrity to the whole center. ",
    },
    {
        id: 3,
        name: 'Alimar Headquarter',
        type: 'COMMERCIAL',
        cover: projectPhotos('03', 0)[0],
        plan: projectPlans('03', 3),
        image: projectPhotos('03', 13),
        thumbnail: projectThumbnails('03'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Alimar Jenerator Company which exports to many countries abroad and has a sales and technical service network in all regions within the country has its headquarters located in Ankara. We divided the headquarter into layers and proceeded with a design line for each floor according to its stages. The highlight of the building is the basement floor which includes an interactive gaming and several lounge areas designed to clear the minds of employees and minimize workplace stress and anxiety in order to increase work efficiency. The geometric carpect design and the window films on the joninery flow in a fluild line with each other on the office floors.",
    },
    {
        id: 4,
        name: 'Villa T',
        type: 'RESIDENTIAL',
        cover: projectPhotos('04', 0)[0],
        plan: projectPlans('04', 1),
        image: projectPhotos('04', 18),
        thumbnail: projectThumbnails('04'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Villa T, the second villa we have designed in Rinnova İncek Site, is suitable for a family of 4 and also guests. It was designed to meet all demands and needs that our clients could ask for. We designed each room according to the needs of its architectural structure. The architecture of this housing estate allows us to create light-filled and comfortable spaces especially in the living room. Since this was the second villa we designed in Rinnova İncek, we had the opportuniy to add more features.",
    },
    {
        id: 5,
        name: 'Villa A',
        type: 'RESIDENTIAL',
        cover: projectPhotos('05', 0)[0],
        plan: projectPlans('05', 1),
        image: projectPhotos('05', 18),
        thumbnail: projectThumbnails('05'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Villa A is located in Beytepe, Ankara. One of the highlights of this villa is the bar section we designed in the living room which has became the most enjoyable part of the house. By adding our design language to all points with this attitude, we managed to please the household with the quality of life we add to the house.",
    },
    {
        id: 6,
        name: 'Villa P',
        type: 'RESIDENTIAL',
        cover: projectPhotos('06', 0)[0],
        plan: projectPlans('06', 1),
        image: projectPhotos('06', 17),
        thumbnail: projectThumbnails('06'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "Villa P was built in Rinnova İncek, Ankara which is far from the city but has become a city in its own way with its many developing structures. The client for this particular house had an interest in Scandinav interior design aesthetics. We tried to take these qualities as base by adding our own design purpose and principles. This project brought us another project in the same housing estate. ",
    },
    {
        id: 7,
        name: 'House T',
        type: 'RESIDENTIAL',
        cover: projectPhotos('07', 0)[0],
        plan: projectPlans('07', 0),
        image: projectPhotos('07', 14),
        thumbnail: projectThumbnails('07'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "House T is an project that came to us through a customer who was satisfied with our work. This is a 4+1 flat in a complex, in Ankara. Aiming to create a warm but bright atmosphere by using powder tones, especially in the entrance area improve the atmosphere of living at home. The polyurethane lath use on walls and ceiling and the serration details on fixed furnitures adds movement to the whole flat.",
    },
    {
        id: 8,
        name: 'House U',
        type: 'RESIDENTIAL',
        cover: projectPhotos('08', 0)[0],
        plan: 0,
        image: projectPhotos('08', 7),
        thumbnail: projectThumbnails('08'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "This bathroom is designed for time to spend comfortably in it. The bathtub area is especially became the most joyful and also relaxing space after the product/accessories selections with our client which we support throughout the entire process. ",
    },
    {
        id: 9,
        name: 'Villa Mansion',
        type: 'RESIDENTIAL',
        cover: projectPhotos('09', 0)[0],
        plan: projectPlans('09', 2),
        image: projectPhotos('09', 20),
        thumbnail: projectThumbnails('09'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "This particular villa was emerged by combining by two twin villas located in İncek, Ankara. It was designed for a family of 4. Since the size of the house is very large, our main goal was to make it feel like home before anything else. In terms of style, American country house qualities were taken as basis in design. Light colored furnitures with bohemian touches combined with large comfy spaces. In away that leaves no room for formality but only tranquility.",
    },
    {
        id: 10,
        name: 'SuperPlus Clinic',
        type: 'COMMERCIAL',
        cover: projectPhotos('10', 0)[0],
        plan: projectPlans('10', 1),
        image: projectPhotos('10', 10),
        thumbnail: projectThumbnails('10'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "A deconstructive design approach was used in this plastic surgery clinic SuperPlus, located in İstanbul. This design brings a new understanding to the concept of hygiene in clinics with alternative materials and dynamic geometries. Blending the intensity of the orange color and the nautral oak veneer is a prominent point in the concrete atmosphere.",
    },
    {
        id: 41,
        name: 'Ranch House',
        type: 'RESIDENTIAL',
        cover: projectPhotos('41', 0)[0],
        plan: projectPlans('41', 5),
        image: projectPhotos('41', 20),
        thumbnail: projectThumbnails('41'),
        coverText: 'Emrace mediterranean tranquility in this timeless architecture',
        brief: "In the realm of this ranch house interior design, a harmonious blend of natural materials and expansive windows defines a timeless and inviting aesthetic. Large windows generously frame picturesque landscapes, allowing an abundance of natural light to grace the interiors while fostering a seamless connection between indoor and outdoor spaces. Incorporating vintage or retro pieces that evoke a sense of nostalgia. Mid-century modern furniture or retro accessories can seamlessly blend with the ranch house aesthetic.  ",
    },
]

export const references = [
    'A Tasarım-Mimarlık',
    'A&M.Ş. Konutu',
    'A-la İskender',
    'AL-CAP Fabrika Binası',
    'Alimar Jeneratör',
    'Altınyapı İnşaat',
    'Artcode',
    'Atabilge Konutları',
    'Atilla Makina',
    'Ayan Ambulans',
    'Ayşin Sevgi Mimarllık',
    'Baghdad Hotel - Iraq',
    'Blen Seramik',
    'Bilkent Doruk Evleri',
    'Bütüner Mimarlık',
    'Can İnşaat',
    'CerModern',
    'Çetin Çataloğlu Ofis',
    'Çimen Express',
    'Detay Yapı',
    'DHK Avro Towers',
    'Doruk İnşaat',
    'Ege Group',
    'Egemen İnşaat',
    'Enfes Cafe - Ankara',
    'Elmar Konutları',
    'En-Ar Yapı',
    'Eskihisar Şirketler Grubu',
    'Evren Özdemir Villası',
    'Evren Yiğit Mimarlık',
    'F.B. Villası',
    'F.G. Villası',
    'Flat Mobilya',
    'Gaye Otel - Bolu',
    'Gazi Üniversitesi - Ankara',
    'Gökçüoğlu Konağı',
    'Gökhan Aksoy Mimarlık',
    'Göyenç Villası',
    'Gussano Mobilya',
    'Günaydın Kebap Ankara',
    'Gülsan Holding - Antalya',
    'Gülsan Mobilya',
    'Gürbağ İnşaat',
    'Hasmer',
    'Hisarcıklıoğlu A.Ş.',
    'Honour Otel',
    'I-Wall',
    'Joyport',
    'Kabara - Tuğba Çanta',
    'Kamrusepa Samyoung',
    'Kentpark AVM',
    'Koçak İnşaat',
    'Konsey Elektrik A.Ş.',
    'Korkmaz İnşaat',
    'Konsept İstanbul LTD.',
    'Lab D-sign',
    'Lamphouse',
    'M & E.G. Konutu',
    'Macarons De Antoinette',
    'Macera Adası',
    'Maya Bakery',
    'Mavi Peri Mimarlık LTD.',
    'Medova Hastanesi',
    'Mesa Urla Çağla Denizaltı Villası',
    'Metro Group',
    'Metron İnşaat',
    'Moco',
    'MTK Architects',
    'Nova Tower Residence',
    'Onur Akkılıç Konutu',
    'Park Vadi F.S. Villası',
    'Paro Yapı',
    'Pierre Cardin Mobilya',
    'Prokon',
    'Profit Neşet Güne LTD',
    'Rönesan LTD',
    'Rönesans Holding',
    'RollHouse Bilkent Ankara',
    'RollHouse Bilkent Ankara',
    'S & K.A. Konutu',
    'Sahara Peyzaj',
    'Sanat Yapı A.Ş.',
    'Sherwood Mobilya',
    'Ş & C. K. Villası',
    'Şahika Kuaför',
    'Şahin Mimarlık',
    'Şahin Tırpan Konutu',
    'Şener Villası',
    'Sinpaş İncek Life',
    'Tabouret Design',
    'TAI',
    'Ted Koleji Ankara',
    'Ted Üniversitesi',
    'Temson',
    'TıHouse',
    'Treso İç Mimarlık',
    'Tunalı Balıkçısı',
    'Turuncu Mimarlık',
    'Upem',
    'YDA',
    'Ülkem İnşaat',
    'Yıbak İnşaat',
    'Yüksel İnşaat',
    '4S'
]