import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';

//IMPORT LOGO 
import LogoWhite from '../media/svg/logo-white-text-03.svg'
import LogoBlack from '../media/svg/logo-black-text-03.svg'

//IMPORT ICONS
import { AiFillMail, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'
import { MdArchitecture, MdSearch } from 'react-icons/md'
import { Squash as Hamburger } from 'hamburger-react'

const Navbar = ({ section, currentPosition, homeCount, gallery, projects }) => {

    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [projectDropwdown, setProjectDropwdown] = useState(false)
    const [menu, setMenu] = useState(false)
    const [search, setSearch] = useState(false)
    const [menuCount, setMenuCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);

    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Animasyonlu bir şekilde kaydırma yapmak için
        });
        navigate(path)
    }

    const handleHMouseEnter = () => {
        setProjectDropwdown(true)
    }

    const handleHMouseLeave = () => {
        setProjectDropwdown(false)
    }

    const toggleSearch = () => {
        setSearch(!search);
        if (!search) {
            // search aktif olmadığında ve aktifleştirildiğinde input'a odaklan
            setTimeout(() => {
                inputRef.current.focus();
            }, 0);
        }
    };

    useEffect(() => {
        if (currentPosition > 0) {
            setProjectDropwdown(false);
        }
    }, [currentPosition]);

    useEffect(() => {
        let interval;
        clearInterval(interval);

        if (menu) {
            setMenuCount(0); // Menu true olduğunda sayacı sıfırla
            interval = setInterval(() => {
                setMenuCount((prevCount) => {
                    if (prevCount === 6) {
                        clearInterval(interval); // Sayacı sıfırla
                        return 6; // 5'te kalsın
                    } else {
                        return prevCount + 1;
                    }
                });
            }, 250);
        }

        return () => clearInterval(interval);
    }, [menu]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredProjects([]);
        } else {
            const filtered = projects.filter(project =>
                project.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredProjects(filtered);
        }
    }, [searchQuery]);

    return (
        <div>
            {/* NAVBAR */}
            <div className={`fixed w-full z-50 custom-font
                    ${section === 0 && !menu ? 'bg-transparent h-[120px] duration-500' : ''}
                    ${section === 1 && !menu ? 'bg-[#1a1a1a]/100 h-[80px] duration-500' : ''}
                    ${section === 2 && !menu ? 'bg-[#1a1a1a]/100 h-[80px] duration-500' : ''}
                    ${section === 3 && !menu ? 'bg-[#1a1a1a]/100 h-[80px] duration-500' : ''}
                    ${section >= 4 && !menu ? 'bg-[#1a1a1a]/100 h-[80px] duration-500' : ''}
                    ${section === 20 && !menu ? 'bg-white/100 h-[80px] duration-500' : ''}
                    ${menu ? 'bg-black/100 h-[80px] duration-1000' : ''}`}>
                <div className='max-w-screen-2xl m-auto w-full h-full px-5 2xl:px-0 flex flex-row justify-between items-center'>
                    {/* Corporate identity Line */}
                    <div style={{ height: `${homeCount === 0 ? `${0}px` : `${420 - currentPosition}px`} ` }} className={`${gallery ? 'hidden' : ''} ${menu ? 'hidden' : ''} ${section >= 2 ? 'opacity-0' : ''} absolute top-[-60px] duration-500 w-[2px] rounded-full bg-white/20 2xl:bg-white/80 ml-[-10px] 2xl:ml-[-40px] `}>
                    </div>
                    {/* LOGO */}
                    <a href='/'>
                        <img className={`${section >= 1 || menu ? 'w-40' : 'w-48 md:w-64'} ${menu ? 'w-40' : ''} cursor-pointer duration-500`} src={section >= 20 && !menu ? LogoBlack : LogoWhite} alt=''></img>
                    </a>
                    {/* <div className='text-white text-2xl'>{section}</div>
                    <div className='text-white text-2xl'>{currentPosition}</div> */}
                    {/* PAGES */}
                    <div className={`${section >= 20 ? 'text-black' : 'text-white'} hidden md:flex flex-row gap-10 text-md flex-shrink-0`}>
                        {/* PROJECT */}
                        <div onMouseEnter={() => handleHMouseEnter()} onMouseLeave={() => handleHMouseLeave()} className={`relative rounded-md`}>
                            <div className='flex flex-row items-center gap-2'>
                                <MdArchitecture onClick={() => setProjectDropwdown(!projectDropwdown)} className={`cursor-pointer hover:opacity-75 duration-500 ${projectDropwdown ? '-rotate-90' : ''}`} size={20} />
                                <Link to='/projects'>
                                    <div className='cursor-pointer hover:opacity-75 duration-500'>Projects</div>
                                </Link>
                            </div>
                            {/* PROJECT DROPDOWN */}
                            <div className={`absolute left-1/2 transform -translate-x-1/2 flex flex-col justify-center w-[120px] gap-5 duration-500 text-white ${projectDropwdown ? 'rounded-lg' : ' h-0'}`}>
                                <div className={`rounded-md relative  ${projectDropwdown ? '' : 'hidden'}`}>
                                    {/* ALL */}
                                    <div className='opacity-0 flex flex-row items-center gap-5 rounded-lg p-2 cursor-pointer'>
                                        ALL
                                    </div>
                                    <div className='absolute top-8 shadow-lg py-2 px-4 bg-black/10 rounded-md'>
                                        {/* ALL */}
                                        <div onClick={() => handleNavigation('/projects/#ALL')} className='flex flex-row items-center gap-5 hover:opacity-75 rounded-lg p-2 cursor-pointer '>
                                            ALL
                                        </div>
                                        {/* COMMERCIAL */}
                                        <div onClick={() => handleNavigation('/projects/#COMMERCIAL')} className='flex flex-row items-center gap-5 hover:opacity-75 rounded-lg p-2 cursor-pointer '>
                                            COMMERCIAL
                                        </div>
                                        {/* RESIDENTIAL */}
                                        <div onClick={() => handleNavigation('/projects/#RESIDENTIAL')} className='flex flex-row items-center gap-5 hover:opacity-75 rounded-lg p-2 cursor-pointer '>
                                            RESIDENTIAL
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ABOUT */}
                        <div onClick={() => handleNavigation('/#about')} className='cursor-pointer hover:opacity-75 duration-500'>About</div>
                        {/* PROFESSIONAL */}
                        <a href='/professional'>
                            <div className='cursor-pointer hover:opacity-75 duration-500'>Professional</div>
                        </a>
                        {/* CONTACT */}
                        <div onClick={() => handleNavigation('/#contact')} className='cursor-pointer hover:opacity-75 duration-500'>Contact</div>
                        {/* SEARCH */}
                        <div className={`flex flex-row justify-center items-center ${search ? 'gap-5' : ''} relative`}>
                            <MdSearch onClick={toggleSearch} className={`cursor-pointer hover:opacity-75 duration-500`} size={20}/>
                            <input ref={inputRef} onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} type='text' placeholder='Search Project..' className={`${search ? 'w-72' : 'w-0'} duration-500 bg-transparent outline-b-2 border-white focus:outline-none`}></input>
                            <div className={`absolute left-1/2 transform -translate-x-1/2 flex flex-col justify-center w-96 gap-5 duration-500 text-white top-10`}>
                                <div className={`rounded-md h-96 overflow-auto flex flex-col ${search ? '' : 'hidden'} ${searchQuery === '' ? '' : 'border-2 border-white/10 bg-black/50'}`}>
                                    {filteredProjects.map((project, index) => (
                                        <a href={`/projects/${project.name.split(' ').join('-')}`}>
                                            <div key={index} className="flex flex-row items-center p-4 border-2 border-transparent hover:border-white/50 duration-500 cursor-pointer">
                                                <img src={project.image[0]} alt={project.name} className=" w-20 h-20 object-cover" />
                                                <div className="text-start mt-2 p-4">
                                                    <h3 className="text-lg">{project.name}</h3>
                                                    <p className="text-sm text-gray-400">{project.type}</p>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MOBILE MENU ICON */}
                    <div className={`${section >= 20 && !menu ? 'text-black' : 'text-white'} flex flex-row gap-5 md:hidden`}>
                        <Hamburger size={25} toggled={menu} toggle={setMenu} />
                    </div>
                </div>
            </div>
            {/* MOBILE MENU */}
            <div className={`${menu ? 'h-full w-full opacity-1' : 'h-0 opacity-0'} fixed md:bg-transparent top-[80px] z-40 duration-500 custom-font right-0 bg-black`}>
                <div
                    className={`${menu ? '' : 'hidden'} text-white cursor-pointer flex flex-col justify-center items-center gap-5 pt-20 z-50`}
                >
                    {/* Home */}
                    <div className={`${menuCount >= 1 ? 'pt-0' : 'opacity-0 pt-20'} px-4 duration-500`}>
                        <Link to='/'>
                            <div>Home</div>
                        </Link>
                    </div>
                    {/* About */}
                    <div className={`${menuCount >= 2 ? 'pt-0' : 'opacity-0 pt-20'} px-4 duration-500`}>
                        <div onClick={() => { handleNavigation('/#about'); setMenu(false) }}>About</div>
                    </div>
                    {/* Projects */}
                    <div className={`${menuCount >= 3 ? 'pt-0' : 'opacity-0 pt-20'} px-4 duration-500`}>
                        <Link to='/projects'>
                            <div >Projects</div>
                        </Link>
                    </div>
                    {/* Professional */}
                    <div className={`${menuCount >= 4 ? 'pt-0' : 'opacity-0 pt-20'} px-4 duration-500`}>
                        <Link to='/professional'>
                            <div >Professional</div>
                        </Link>
                    </div>
                    {/* Contact */}
                    <div className={`${menuCount >= 5 ? 'pt-0' : 'opacity-0 pt-20'} px-4 duration-500`}>
                        <div onClick={() => { handleNavigation('/#contact'); setMenu(false) }}>Contact</div>
                    </div>

                    {/* Social Media */}
                    <div className={`${menuCount >= 6 ? 'pt-5' : 'opacity-0 pt-20'} flex flex-row items-center gap-4 text-white duration-500`}>
                        <a href='mailto:info@manoto.com.tr'>
                            <AiFillMail className='cursor-pointer duration-500 opacity-75 text-xl md:text-2xl' />
                        </a>
                        <a href='https://www.instagram.com/manotointeriors'>
                            <AiFillInstagram className='cursor-pointer duration-500 opacity-75 text-xl md:text-2xl' />
                        </a>
                        <a href='https://www.linkedin.com/company/manoto-design/' target='_blank'>
                            <AiFillLinkedin className='cursor-pointer duration-500 opacity-75 text-xl md:text-2xl' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar