import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';

//IMPORT ICONS
import { FaLocationArrow } from 'react-icons/fa'
import { AiFillInstagram, AiFillLinkedin, AiFillMail } from 'react-icons/ai'
import { BsFillTelephoneFill } from 'react-icons/bs'
import Logo from '../media/svg/logo-white-02.svg'

//BUTTONS
import MentionsButton from '../components/button/MentionsButton'

//COMPONENTS
import Navbar from "../components/Navbar";
import HomeText from "../components/HomeText";
import HomeVideo from "../components/HomeVideo";
import ContactMap from '../components/ContactMap'
import Footer from '../components/Footer'
import ProjectGallery from '../components/ProjectGallery'

const Home = ({ projects }) => {
    //STATE
    const location = useLocation();
    const [homeCount, setHomeCount] = useState(0)
    const [hScreenHeight, setHScreenHeight] = useState(window.innerHeight);
    const [section, setSection] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0)
    const [videoLoad, setVideoLoad] = useState(false)

    //REF
    const sliderRef = React.createRef();

    //FUNCTIONS
    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const goToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView();
        }
    };

    //COUNT HOME TEXT
    useEffect(() => {
        const interval = setInterval(() => {
            if (homeCount < 7) {
                setHomeCount(homeCount + 1);
            }
        }, 500);
        return () => clearInterval(interval);
    }, [homeCount]);

    //SECTION UPDATE
    useEffect(() => {
        const handleScroll = () => {
            const updatedPosition = window.scrollY;
            setCurrentPosition(updatedPosition);
            let newSection;
            if (updatedPosition === 0) {
                newSection = 0;
            } else {
                newSection = Math.floor((updatedPosition - 1) / (hScreenHeight / 4));
            }
            setSection(newSection);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [hScreenHeight]);

    //UPDATE URL AND NAVIGATE 
    useEffect(() => {
        const homeURL = location.hash.substring(1); // '#RESIDENTIAL' -> 'RESIDENTIAL'
        if (['about', 'contact', 'projects'].includes(homeURL)) {
            goToSection(homeURL);
        } else {
            goToSection('home');
        }
    }, [location, videoLoad]);

    const settingsGallery = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        arrows: false,
        swipe: false,
    };

    return (
        <div className="bg-black custom-font">
            {/* LOADING */}
            <div style={{ height: `${hScreenHeight}px` }} className={`flex flex-col justify-center items-center text-white relative ${videoLoad ? 'hidden' : ''}`}>
                <div className="w-24 h-32 flex">
                    <img className="w-16 object-cover m-auto  ease-out duration-500" src={Logo} alt="manoto-logo"></img>
                </div>
                <div className="w-full">
                    <span className="loader"></span>
                </div>
            </div>
            <div className={`${videoLoad ? '' : 'hidden'}`}>
                <MentionsButton />
                <Navbar section={section} currentPosition={currentPosition} homeCount={homeCount} projects={projects} />
                {/* HOME */}
                <div id="home" style={{ height: `${hScreenHeight}px` }} className="w-full h-full">
                    <HomeVideo section={section} setVideoLoad={setVideoLoad} videoLoad={videoLoad} />
                    <div className="absolute w-full h-full bg-black/75 md:bg-black/75"></div>
                    <div className="h-[120px] w-full bg-transparent"></div>
                    {/* TEXT AREA */}
                    <div style={{ height: `${hScreenHeight - 240}px ` }} className='w-full relative flex flex-col'>
                        <div className="w-full max-w-screen-xl m-auto z-30 ">
                            <div className="w-full flex ">
                                <div className="px-5 md:px-20 w-full m-auto">
                                    <HomeText homeCount={homeCount} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[120px] w-full bg-transparent"></div>
                    {/* FOOTBAR */}
                    <div className='absolute h-[120px] w-full px-5 2xl:px-0 max-w-screen-2xl mx-auto left-0 right-0 bottom-0 justify-between z-30 text-white flex flex-row items-center'>
                        <div className="flex flex-row gap-5">
                            <a href="mailto:info@manoto.com.tr">
                                <AiFillMail className='cursor-pointer duration-500 hover:opacity-75 text-lg md:text-xl' />
                            </a>
                            <a href="https://www.instagram.com/manotointeriors" target="_blank">
                                <AiFillInstagram className='cursor-pointer duration-500 hover:opacity-75 text-lg md:text-xl' />
                            </a>
                            <a href="https://www.linkedin.com/company/manoto-design/" target="_blank">
                                <AiFillLinkedin className='cursor-pointer duration-500 hover:opacity-75 text-lg md:text-xl' />
                            </a>
                        </div>
                        <div className="relative">
                            <div style={{
                                height: `${homeCount === 0 ? `${0}px` : `${420 - currentPosition}px`} `,
                            }} className={`absolute bottom-[-60px] duration-500 w-[2px] rounded-full bg-white/20 2xl:bg-white/80 z-50 ml-[10px] 2xl:ml-[40px]`}>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ABOUT */}
                <div id="about" style={{ height: `${hScreenHeight}px` }} className="w-full h-full relative ">
                    <div className="h-[80px] w-full bg-transparent"></div>
                    <div style={{ height: `${hScreenHeight - 160}px ` }} className='w-full relative flex '>
                        {/* TEXT AREA */}
                        <div className={`${section === 2 || section === 3 || section === 4 || section === 5 ? 'opacity-100' : 'opacity-0'} w-full max-w-screen-xl m-auto z-30 duration-1000 `}>
                            <div className="w-full flex ">
                                <div className="px-5 md:px-10 w-full m-auto">
                                    <div className={`flex flex-col gap-2 h-full duration-1000 relative justify-center items-center md:items-center`}>
                                        <div className="absolute w-full h-full">
                                            <img className='opacity-5 z-20 w-96 md:w-full md:scale-125 m-auto h-full border-2 border-black' src={Logo}></img>
                                        </div>
                                        <div className='text-2xl md:text-4xl custom-font-SemiBold text-white z-20 md:w-96 text-justify'>COMPANY STANDPOINT</div>
                                        <div className='flex flex-col gap-2 md:gap-10 md:pt-5 z-20 md:w-96 '>
                                            <div className='w-full md:w-96 md:text-sm custom-font-light text-white/80 text-justify'>MANOTO is a Design Studio that has been dedicated to Interior Design since 2008. Their objective in their work is to create timeless, value-added, livable, unique, and high-quality designs. In this context, they provide services to both individuals and businesses with a distinctive style.</div>
                                            <div className='w-full md:w-96 md:text-sm custom-font-light text-white/80 text-justify'>MANOTO, with its boutique service approach, strives to maintain its existence on a 'human scale'. They continue their work aiming for excellence day by day. Thanks to their team, which is curious about new technology, art, and science, they continue on their path in the disciplines of Interior Architecture with a different perspective, including Design, Project, Construction, and Consultancy.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[80px] w-full bg-transparent"></div>
                    {/* FOOTBAR */}
                    <div className='absolute h-[120px] w-full px-5 2xl:px-0 max-w-screen-2xl mx-auto left-0 right-0 bottom-0 justify-between z-30 text-white flex flex-row items-center'>
                        <div className="flex flex-row gap-5">
                        </div>
                        <div className='flex flex-row text-white gap-5 custom-font py-5'>
                            {/* <div onClick={() => goToSection('projects')} className={`${homeCount >= 7 ? 'opacity-100' : 'opacity-0'} duration-500 hover:bg-white/20 flex items-center justify-end cursor-pointer text-xs md:text-sm vertical-text py-2 px-4 md:py-4 md:px-10 flex-col gap-1 rounded-md`}>
                            <div>Projects</div>
                        </div>
                        <div onClick={() => goToSection('contact')} className={`${homeCount >= 7 ? 'opacity-100' : 'opacity-0'} duration-500 hover:bg-white/20 flex items-center justify-end cursor-pointer text-xs md:text-sm vertical-text py-2 px-4 md:py-4 md:px-10 flex-col gap-1 rounded-md`}>
                            <div>Contact</div>
                        </div> */}
                        </div>
                    </div>
                </div>
                {/* PROJECTS */}
                <div id="projects" className="w-full h-full relative">
                    {/* <div className="h-[80px] w-full bg-transparent"></div> */}
                    <div className={`${section === 5 || section === 6 || section === 7 || section === 8 || section === 9 || section === 10 ? 'opacity-100' : 'opacity-0'} h-full w-full max-w-screen-2xl m-auto z-10 relative py-5 duration-500`}>
                        <div className="custom-font-SemiBold text-white max-w-screen-2xl w-full m-auto px-5 2xl:px-0 text-lg md:text-2xl text-start pt-4 pb-2"> PROJECTS</div>
                        <ProjectGallery projects={projects} />
                        <div className="hidden md:block custom-font-SemiBold text-white max-w-screen-2xl w-full m-auto px-5 2xl:px-0 text-lg md:text-2xl text-start pt-4 pb-2"> RECENT PROJECT</div>
                        <div className="hidden md:block w-full h-96 pt-1 px-5 2xl:px-0">
                            <Slider {...settingsGallery} ref={sliderRef}>
                                {projects.slice().reverse().map((project, index) => (
                                    <div key={index}>
                                        <div className="h-96 w-full relative custom-font flex flex-row gap-1">
                                            <a href={`/projects/${project.name.split(' ').join('-')}`} className="w-1/3 h-full relative cursor-pointer">
                                                <div className=" project-item w-full h-full">
                                                    <div className="bg-black/40 hover:bg-black/0 absolute w-full h-full z-10"></div>
                                                    <div className="absolute w-full h-full flex flex-col justify-center items-center z-20 text-white ">
                                                        <div className="text-md md:text-2xl">{project.name}</div>
                                                        <div className="text-xs md:text-lg text-white/60">{project.type}</div>
                                                    </div>
                                                    <img className='w-full h-full -z-20 object-cover' src={project.image[1]} alt={`${project.name.split(' ').join('-')}`}></img>
                                                </div>
                                            </a>
                                            <div className="w-1/3 m-auto h-full hidden xl:block">
                                                <img src={project.cover} className="object-cover w-full h-full" alt={`${project.name.split(' ').join('-')}`}></img>
                                            </div>
                                            <div className="xl:w-1/3 w-2/3 m-auto h-full px-2 flex flex-col justify-center gap-2 relative">
                                                <a href={`/projects/${project.name.split(' ').join('-')}`}>
                                                    <div className="text-white custom-font-SemiBold text-2xl md:text-4xl cursor-pointer hover:opacity-75 duration-500"> {project.name} </div>
                                                </a>
                                                <div className="text-white custom-font-light md:text-sm">{project.brief.slice(0, 250)}...</div>
                                                {/* READ MORE */}
                                                <div className="flex justify-end">
                                                    <a href={`/projects/${project.name.split(' ').join('-')}`}>
                                                        <div className="text-white text-sm hover:opacity-75 cursor-pointer duration-500 custom-font">Read More..</div>
                                                    </a>
                                                </div>
                                                <div className="absolute bottom-2 left-2 hover:cursor-pointer text-white self-end hover:opacity-75 duration-500 md:text-md" onClick={() => goToPrevSlide()}>
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <img src={project.cover} className={`${index > 0 ? '' : 'opacity-0'} h-6 w-6`} ></img>
                                                        <div>{index > 0 ? projects.slice().reverse()[index - 1].name : ''}</div>
                                                    </div>
                                                </div>
                                                {/* <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 hover:cursor-pointer text-white self-end hover:opacity-75 duration-500" onClick={() => handleNavigation('/projects')}>
                                                <MdArchitecture size={25} className="hover:rotate-180 duration-500" />
                                            </div> */}
                                                <div className="absolute bottom-2 right-2 hover:cursor-pointer text-white self-end hover:opacity-75 duration-500 md:text-md" onClick={() => goToNextSlide()}>
                                                    <div className="flex flex-row-reverse gap-2 items-center">
                                                        <img src={project.cover} className={`${index < projects.slice().reverse().length - 1 ? '' : 'opacity-0'} h-6 w-6 `} ></img>
                                                        <div>{index < projects.slice().reverse().length - 1 ? projects.slice().reverse()[index + 1].name : ''}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className="md:h-[40px] w-full bg-transparent"></div>
                </div>
                {/* CONTACT */}
                <div id="contact" style={{ height: `${hScreenHeight}px` }} className="w-full h-full relative ">
                    <div className="h-[80px] w-full bg-transparent"></div>
                    <div style={{ height: `${hScreenHeight - 160}px ` }} className={`${section >= 9 ? 'opacity-100' : 'opacity-0'} w-full relative flex flex-col duration-1000`}>
                        <div className="custom-font-SemiBold text-white max-w-screen-2xl w-full m-auto px-5 2xl:px-0 pt-4 pb-2 text-lg md:text-2xl "> CONTACT US</div>
                        {/* TEXT AREA */}
                        <div className="w-full h-full max-w-screen-2xl px-5 2xl:px-0 m-auto z-30 flex flex-col justify-end">
                            <div className="h-full w-full lg:w-1/2">
                                <ContactMap />
                            </div>
                            <div className='flex flex-col md:flex-row py-5 z-20 w-full'>
                                <div className='w-full text-sm md:text-base custom-font text-white'>
                                    <div className="text-gray-400">General Questions</div>
                                    <a href="mailto:info@manoto.com.tr">
                                        <div className='w-36 md:w-48 flex flex-row items-center gap-4 cursor-pointer hover:text-gray-400 duration-500'>
                                            <AiFillMail className=' shrink-0 text-gray-400' />
                                            <div>info@manoto.com.tr</div>
                                        </div>
                                    </a>
                                    <div className="pt-2 text-gray-400">Career</div>
                                    <a href="mailto:cv@manoto.com.tr">
                                        <div className='w-36 md:w-48 flex flex-row items-center gap-4 cursor-pointer hover:text-gray-400 duration-500'>
                                            <AiFillMail className=' shrink-0 text-gray-400' />
                                            <div>cv@manoto.com.tr</div>
                                        </div>
                                    </a>
                                    <div className="pt-2 text-gray-400">Contact Us</div>
                                    <a href="tel:+90 (312) 426 36 33">
                                        <div className='w-36 md:w-48 flex flex-row items-center gap-4 cursor-pointer hover:text-gray-400 duration-500'>
                                            <BsFillTelephoneFill className=' shrink-0 text-gray-400' />
                                            <div>0 (312) 426 36 33</div>
                                        </div>
                                    </a>
                                    <div className="pt-2 text-gray-400 ">Visit Us</div>
                                    <a href="https://www.google.com/maps/search/%C3%87ankaya+Mahallesi+-+Atat%C3%BCrk+Bulvar%C4%B1+No:243+%C4%B0lbank+Bloklar%C4%B1+A2%2F84+%C3%87ANKAYA+ANKARA+06690/@39.8995751,32.8580293,17z/data=!3m1!4b1?entry=ttu" target="_blank">
                                        <div className='flex flex-row items-center gap-4 max-w-xs cursor-pointer hover:text-gray-400 duration-500'>
                                            <FaLocationArrow className=' shrink-0 text-gray-400' />
                                            <div>Çankaya Mahallesi - Atatürk Bulvarı No:243 İlbank Blokları A2/84 ÇANKAYA ANKARA 06690</div>
                                        </div>
                                    </a>
                                    {/* <div className="pt-2 text-gray-400">Social Media</div>
                                <div className='pt-2 flex flex-row items-center gap-4 text-white'>
                                    <AiFillMail onClick={() => { handleMailSend('info@manoto.com.tr') }} className='cursor-pointer duration-500 opacity-75 text-lg md:text-xl' />
                                    <AiFillInstagram onClick={() => openLink('https://www.instagram.com/manotointeriors')} className='cursor-pointer duration-500 opacity-75 text-lg md:text-xl' />
                                    <AiFillLinkedin onClick={() => openLink('https://www.linkedin.com/company/manoto-design/')} className='cursor-pointer duration-500 opacity-75 text-lg md:text-xl' />
                                </div> */}
                                    <div className="hidden md:block h-[80px] w-full bg-transparent"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer section={false} />
                <div className="h-[80px] w-full bg-transparent"></div></div>
        </div >
    );
};

export default Home;


